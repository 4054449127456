.order {
  .red {
    color: #ff0000;
  }

  .green {
    color: #22ad16;
  }

  .with-link {
    display: inline-block;
    min-width: 0;
    max-width: 123px;
  }

  &__card-header {
    border-bottom: solid 1px #ededed !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  & .header-page__left .h3 {
    margin-right: 16px;
  }

  &__header-number {
    margin-bottom: 0;
  }

  &__carried-out-button {
    display: flex;
    align-items: center;
    margin-left: 15px;

    & .order__date-block {
      margin-right: 8px;

      &.shipping-date input {
        width: 165px;
      }
    }
  }

  & .elem {
    padding: 10px 35px 15px 35px;
    border-bottom: solid 1px #ededed !important;

    &.bottom {
      display: flex;
      flex-direction: column;
    }

    &-field {
      width: 235px;
      margin-right: 12px;

      &.delivery-settings {
        width: 660px;
      }

      &.contract {
        width: 460px;
      }

      &.customer {
        width: 460px;
      }

      &.comment {
        width: 710px;
      }
    }

    & .button-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 15px;
      margin-bottom: 22px;

      & .btn {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .sent-diadoc {
    margin: -5px 5px 0 0;
  }

  .status .btn {
    width: 145px;
  }

  .balance {
    margin-top: 33px;
  }

  &__date-block {
    display: flex;
    align-items: center;
    margin-left: 37px;

    .date {
      width: 110px;
    }

    & .form-label {
      margin-right: 14px;
    }
  }

  .dropdown-item a {
    color: #495057;
    text-decoration: none;
  }

  .select_address>div {
    width: calc(100% - 40px);
  }

  .address {
    display: flex;
    flex-direction: column;

    &>div {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      & span:first-child {
        min-width: 200px;
      }

      & span:last-child {
        min-width: 800px;
      }
    }
  }

  .quantityLabels {
    width: 60px;
  }
}

@media print {
  .order {
    display: none;
  }
}