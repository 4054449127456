@import "~assets/scss/1-variables/classic";

.cute {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter {
  &__type-date {
    display: inline-block;

    &-item {
      padding: 0;
      border: none;
      background: none;
      outline: 0 !important;
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 23px;
      color: #1f9bcf;
      border-bottom: 1px solid #1f9bcf;
      height: 19px;
      transition: color 0.15s ease;

      &.active {
        color: #6e6e6e;
        border-color: transparent;
      }
    }
  }
}
