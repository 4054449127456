@import "~assets/scss/1-variables/classic";

.supply-product {
  &__toolbar {
    margin-top: 20px;
  }

  & .button-block {
    display: flex;
    justify-content: flex-start;

    & .btn {
      margin-right: 10px;
    }
  }

  & .react-select-container.import {
    width: 250px;
    margin-right: 10px;
  }

  .action-table:not(:first-child) {
    margin-left: 8px;
  }

  & tr.error {
    background-color: $red4;
  }

  & td.blue-bg {
    background-color: #8bfbfa;
  }

  & .error {
    padding: 2px 6px;
    border-radius: 3px;
    color: red;
    background-color: #fa979770;
  }

  & .sellBlock {
    width: 100%;
    height: 20px;

    & .sellBlockSelect {
      display: none;
      position: absolute;
      top: calc(50% - 14px);
      left: 5px;
      width: 120px;
      z-index: 1;
    }

    &:hover .sellBlockSelect {
      display: block;
    }
  }
}